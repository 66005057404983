import "@babel/polyfill";
import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import VueAnalytics from "vue-analytics";

import vuetify from "./plugins/vuetify"; // path to vuetify export

// set locale
const moment = require("moment");
require("moment/locale/nl");
Vue.use(require("vue-moment"), {
  moment
});

Vue.config.productionTip = false;
Vue.use(require("vue-moment"));
Vue.use(VueAnalytics, {
  id: "UA-144781802-1",
  router,
  autoTracking: {
    screenview: true
  }
});

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount("#app");
//import "./plugins/vuetify";
