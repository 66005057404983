<template>
  <span class="toolbar">
    <v-app-bar color="orange" dark>
      <v-toolbar-title>
        <router-link to="/">
          <v-img
            :src="require('../assets/logo_white_trans.png')"
            height="40px"
            width="40px"
            style="float: left"
          />
        </router-link>
      </v-toolbar-title>

      <div class="flex-grow-1"></div>
      <div v-if="isAuthenticated !== true">
        <small>v1.2.5 // 24-09-2024</small><br />
        <router-link to="/sign-in"> Login </router-link>
      </div>
      <div v-else>
        <v-btn icon>
          <router-link to="/">
            <v-icon>mdi-webpack</v-icon>
          </router-link>
        </v-btn>
        <v-btn icon>
          <router-link to="/brothers">
            <v-icon>mdi-account-group</v-icon>
          </router-link>
        </v-btn>
        <v-menu right top origin="top right" transition="scale-transition">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-app-bar-nav-icon></v-app-bar-nav-icon>
            </v-btn>
          </template>

          <v-list nav>
            <v-list-item v-for="(item, index) in items" v-bind:key="index">
              <v-list-item-icon>
                <v-icon v-if="item.icon" color="orange">{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-title>
                <router-link :to="item.link">
                  {{ item.title }}
                </router-link>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="orange">mdi-logout-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <a @click="logout">Logout</a>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-btn to="/addnew" v-if="isAdmin">
                <i
                  aria-hidden="true"
                  :class="[
                    'v-icon notranslate mdi mdi-plus theme--light white--text text--lighten-2',
                  ]"
                ></i>
                &nbsp; Voeg nieuwe broeder toe
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
  </span>
</template>
<script>
export default {
  name: "AppNavigation",
  data() {
    return {
      appTitle: "HvT",
      drawer: false,
      url: "/",
      menuset: false,
      items: [
        { title: "Arbeidstafel ", link: "/", icon: "mdi-webpack" },
        { title: "Broeders", link: "/brothers", icon: "mdi-account-group" },
        {
          title: "Maçonnieke leeftijden",
          link: "/ingewijd",
          icon: "mdi-calendar-multiple-check",
        },
        {
          title: "Functies",
          link: "/functions",
          icon: "mdi-account-search",
        },
        {
          title: "Over deze site",
          link: "/about",
          icon: "mdi-information-variant",
        },
      ],
    };
  },
  computed: {
    checkUserdata() {
      return this.$store.state.user;
    },
    addEditLinkToMenu() {
      var vm = this;
      if (this.$store.state.user && this.menuset == false) {
        var url = "/edit/" + this.$store.state.user.userdata.lidnr;
        var objectje = {
          title: "Wijzig mijn gegevens ",
          link: url,
          icon: "mdi-account-edit",
        };
        vm.items.push(objectje);
        vm.menuset = true;
        return true;
      } else {
        return false;
      }
    },
    isAdmin() {
      return this.$store.state.isAdmin;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("userSignOut");
    },
  },
  mounted() {
    this.$root.$on("userSet", () => {
      this.addEditLinkToMenu;
    });
  },
};
</script>
<style scoped>
a {
  color: #3f739c;
  text-decoration: none;
}
.v-application .toolbar a {
  color: #fff !important;
}
.v-menu__content a {
  display: block;
  line-height: 3em;
}
</style>
