import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const opts = {
  icons: {
    iconfont: "mdi" // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
  },
  theme: {
    themes: {
      primary: "#f4951f",
      secondary: "#3f51b5",
      accent: "#cddc39",
      error: "#f44336",
      warning: "#ffeb3b",
      info: "#03a9f4",
      success: "#4caf50",
      anchor: "#fff"
    }
  }
};

export default new Vuetify(opts);
