import Vue from "vue";
import Vuex from "vuex";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/auth";
//import firebase from "firebase/app";
//import "firebase/database";
//import "firebase/auth";
//import "firebase/storage";
import { rtdbPlugin } from "vuefire";
import router from "@/router";
import { event } from "vue-analytics";

Vue.use(Vuex);
Vue.use(rtdbPlugin);
var admins = ["w.tinbergen@gmail.com", "rb@burggraaf.org"];
//var admins = [];
export default new Vuex.Store({
  state: {
    apiUrl: "",
    arbeidstafel: {
      timestamp: Date.now(),
      gegevens: null,
    },
    isAuthenticated: false,
    isAdmin: false,
    lastpage: null,
    loginInErrors: null,
    showNextLodge: true,
    user: null,
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setIsAuthenticated(state, payload) {
      state.isAuthenticated = payload;
    },
    setAdmin(state, payload) {
      state.isAdmin = payload;
    },
    setLoadingErrors(state, payload) {
      state.loginInErrors = payload;
    },
    setShowNextLodge(state, payload) {
      state.showNextLodge = payload;
    },
    setArbeidsTafel(state, payload) {
      state.arbeidstafel.gegevens = payload.datas;
      state.arbeidstafel.timestamp = payload.timestamp;
    },
    setLastPage(state, payload) {
      state.lastpage = payload;
    },
  },
  actions: {
    userLogin({ commit }, { email, password }) {
      commit("setLoadingErrors", null);
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((user) => {
          // dit checken
          if (admins.indexOf(email) !== -1) {
            commit("setAdmin", true);
          }
          commit("setUser", user);
          commit("setIsAuthenticated", true);
          event("authentication", "login", "Succesvol ingelogd");
          router.push("/brothers"); // vergangen naar zinvolle pagina
        })
        .catch((errors) => {
          commit("setUser", null);
          commit("setIsAuthenticated", false);
          event("authentication", "login", "ONsuccesvol ingelogd");
          commit("setLoadingErrors", errors.message);
        });
    },
    userSignOut({ commit }) {
      firebase
        .auth()
        .signOut()
        .then(() => {
          commit("setUser", null);
          commit("setAdmin", false);
          commit("setIsAuthenticated", false);
          commit("setLoadingErrors", null);
          commit("setShowNextLodge", true);
          event("authentication", "loguit", "Succesvol uitgelogd");
          router.push("/sign-in");
        })
        .catch(() => {
          commit("setUser", null);
          commit("setAdmin", false);
          commit("setIsAuthenticated", false);
          commit("setLoadingErrors", null);
          commit("setShowNextLodge", true);
          event("authentication", "loguit", "ONsuccesvol uitgelogd");
          router.push("/");
        });
    },
    userJoin({ commit }, { email, password }) {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((user) => {
          commit("setUser", user);
          commit("setIsAuthenticated", true);
          // hier ook nieuw toevoegen aan fb database
          router.push("/about"); // vergangen naar zinvolle pagina
        })
        .catch(() => {
          commit("setUser", null);
          commit("setIsAuthenticated", false);
          router.push("/"); // gefaald
        });
    },
  },
  getters: {
    isAuthenticated(state) {
      return state.user !== null && state.user !== undefined;
    },
  },
});
